/* ------------------------------
	// Handlevogn
	------------------------------ */

#mcweb-cart-cart h2 {
    padding: 1em 0 0 1em;
}

.body-cart .panel-heading {
    display: none !important;
}

.cart-content .panel-title {
    font-size: 12.8px;
}

.cart-load {
    text-align: center;
    padding-top: 20px;
    font-size: 2em;
}

.cart-content label {
    font-size: 12.8px;
}

.cart-align-right {
    text-align: right;
}

.cart-fee-block {
    display: block;
    clear: both;
}

/* Reset default values */
.cart-content .LayoutVersion2 .VerticalAdContainer {
    display: inline;
    vertical-align: top;
}

.cart-content .StandardArticleMainExBorder {
    height: initial;
}

.cart-content .LayoutVersion2 .ElementDivider {
    height: initial;
}

.cart-content div#Element.ElementStandardArticle {
    height: initial !important;
}
/* /END Reset default values */

/* jQMobile */
.ui-panel-wrapper #cart-page {
    background-color: #fff;
    margin: -16px;
}

.cart-content .col-sm-bak1 {
    margin-left: -25%;
}
/* /jQMobile */


#cart-page #cart .panel-collapse.collapse {
    display: block;
}

#cart-page .panel.panel-default,
#cart-page .panel.cart-panel-hide {
    border: none;
    -ms-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
}

#mcweb-cart-cart #cart-page .panel.panel-default {
    border: 1px solid #ddd;
}

.btn-link-underline {
    text-decoration: underline;
}

.panel.cart-panel-hide {
    border: none;
}

.panel-title {
    cursor: pointer;
}

.cart-content .panel-group .panel.cart-panel-hide .panel-heading + .panel-collapse > .panel-body {
    border: none;
    padding: 0;
}

#cart .panel-body {
    padding-top: 0;
}

@media only screen and (max-width : 768px) {
    #cart .panel-body {
        padding: 0;
    }
}

.panel.cart-panel-hide .panel-heading {
    display: none;
}

.cart-top {
    border-bottom: 1px solid #ccc;
    margin-top: 10px;
    margin-bottom: -20px;
}

.cart-top h1 {
    text-transform: uppercase;
    padding: 0;
    margin: 0.2em 0 0;
    font-size: 13pt;
}

.cart-items .cart-img {
    padding: 0 0 0 5px;
    margin-bottom: 1.5em;
}

.col-text p {
    margin: 0;
}

#checkout .cart-item-count {
    margin-top: 0.2em;
}

.cart-items .col-price.pull-right p {
    text-align: right;
    margin-bottom: 0;
    margin-top: -2px;
}

.cart-item-header {
    font-size: 10pt;
    font-weight: bold;
}

.cart-item-header2 {
    font-size: 9pt;
}

.cart-items-count-help {
    font-weight: normal;
    display: none;
}

.cart-item-textbox {
    margin-bottom: 10px;
}

.cart-item-info {
    margin-bottom: 5px;
}

#carrier-types {
    padding: 1em 0;
}

.cart-carrier-header {
    font-size: 9pt;
}

.cart-carrier-selected {
    margin-bottom: 0.5em;
}

.cart-carrier-selected-name {
    font-weight: bold;
}

.carrier-selected-name {
    font-size: 9pt;
}


a.cart-carrier-show {
    color: #0080c9;
    text-decoration: underline;
    cursor: pointer;
}

.carrier-changed span.bold {
    display: inline-block;
}

.cart-items .carrier-row {
    margin-top: 0.5em;
    margin-bottom: 1em;
}

p.c-price {
    font-size: 10pt;
    font-weight: normal;
}

p.c-price-single {
    font-size: 9pt;
}

.cart-items .delete-col {
    text-align: right;
}

.cart-items .col-delete {
    margin-bottom: 15px;
}

.cart-add-fav {
    margin-right: 6px;
}

.cart-add-fav .btn.favorite-cart-btn {
    background: transparent;
    border: none;
    padding: 0;
    color: #39c;
    margin-bottom: 5px;
    box-shadow: none;
}

.cart-add-fav .btn.favorite-cart-btn i {
    font-size: 21px;
    margin-right: 4px;
}


.cart-add-fav .btn.favorite-cart-btn:active {
    box-shadow: none;
}



.cart-items i.icon-cancel-circled {
    font-size: 15pt;
    color: #333;
}

.cart-total-container .checkbox.mva {
    display: none;
    margin-left: -12px;
}

.cart-items .cart-list-amount {
    font-weight: bold;
    font-size: 11pt;
}
/*
@media only screen and (min-width : 768px) {
    .cart-items .cart-list-amount {
        padding: 0;
    }
}*/

.cart-items .cart-items-invoicefeetxt,
.cart-items .cart-items-invoicefee {
    font-weight: normal;
    font-size: 0.8em;
}

.cart-items .cart-items-invoicefeetxt,
.cart-items .cart-items-invoicefee,
.cart-items .cart-items-totaltxt,
.cart-items .cart-items-totalsum,
.cart-items .cart-items-totalsum-ink-carrier {
    text-align: right;
} 

.cart-items .row-line {
    border-bottom: 1px dotted #ddd;
    margin: 5px 0;
}

.cart-items .row-line.end-line {
    border-bottom: 1px solid #ccc;
}

.col-amount input:disabled {
    background: #fff;
    border: none;
    box-shadow: none;
}

.col-amount.input-group-sm > .form-control {
    padding: 5px 0;
    text-align: center;
}

.col-amount.input-group-sm > .input-group-btn > .btn {
    padding: 0.2em 1em;
    max-height: 2em;
}

.col-amount.input-group-sm .input-group-btn .cart-item-remove {
    border-right: 0;
}

.cart-items .col-price {
    margin-top: -0.4em;
}

.cart-items .price-container {
    float: right;
    margin-top: -33px;
}

.cart-items .col-price-shipping {
    margin-top: -0.5em;
    font-weight: bold;
    font-size: 1em;
}

.checkout .cart-items .col-price-shipping {
    margin-bottom: -1em;
}

.cart-items .row-line-last .hide-carrier {
    display: none;
}

.checkout .cart-items .row-line-last .hide-carrier {
    display: block;
}

.cart-items .col-amount {
    padding-left: 0;
}

.cart-items .col-amount .smart-label {
    display: inline-block;
}

.cart-items .carrier-header-text {
    font-weight: normal;
}

.cart-items .carrier-header-text span {
    font-weight: bold;
}

.cart-items i#qt-zipcode-i {
    font-size: 1.7em;
    vertical-align: sub;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -o-transform: scaleX(1);
    transform: scaleX(1);
    display: inline-block;
}

.cart-items .c-price {
    font-weight: bold;
    font-size: 1em;
    text-align: right;
}

.cart-items .c-price-single {
    font-size: 0.8em;
    float: right;
}

.cart-items .c-price-rrp {
    font-size: 1em;
    float: right;
    text-decoration: line-through;
}

.checkout .cart-items .carrier-price {
    min-height: 5em;
}

.checkout .cart-items .cart-total-container {
    margin-bottom: -0.5em;
}


.cart-items .input-group.shipment-zipcode {
    max-width: 110px;
    padding: 0;
    margin: 20px 0;
}

.cart-items .carrier-price i {
    color: #333;
    font-size: 1.5em;
    margin: 0;
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
}

.row-to-checkout {
    margin: 3em 0 1em 0;
}

.row-to-checkout a.btn.btn-primary.btn-block {
    color: #fff;
}

.qtip-bootstrap.qtip-cart .qtip-content {
    padding: 2px 5px;
}

.cart-content .empty-cart {
    margin-bottom: 30px;
    margin-top: 20px;
    text-decoration: underline;
}

.empty-cart a.cart-continue {
    padding-left: 0;
    text-decoration: underline;
}

.cart-items img.StockIcon {
    width: 12px;
    margin: -3px 3px 0;
}

.cart-items .cart-vat-text,
.cart-items .cart-vat-price {
    font-size: 0.8em;
}

.cart-content .cart-total.pull-right {
    margin-top: -8px;
}

.less-qty-delete-icon {
    font-size: 0.8em;
    margin: 0 -0.6em;
}

.msg-to-sale span {
    -ms-word-wrap: break-word;
    word-wrap: break-word;
}

.msg-to-sale:hover,
.discount-id:hover {
    cursor: pointer;
}

.msg-to-sale i,
.discount-id i {
    color: gray;
    font-size: 9px;
    top: 0;
}

.cart-extras .checkbox.mva {
    text-align: center;
}

.cart-total-container {
    margin-right: 0;
}

.col-amount-xs .input-group {
    min-width: 7em;
    max-width: 7em;
}

.col-amount-xs .input-group .form-control {
    min-width: 3em;
    max-width: 3em;
    max-height: 2em;
}


.cart-carrier-list .list-element:first-child {
    border-top: 1px dashed #dddddd;
}

.cart-items .cart-carrier-list .price {
    margin-right: 4em;
    float: none;
}

.cart-items .cart-carrier-list > div:nth-child(odd) {
    background-color: #FCFCFC;
    border-radius: 3px;
    padding: 0.5em 0 0 0.5em;
}

.cart-items .cart-carrier-list > div:nth-child(even) {
    background-color: #fff;
    padding: 0.5em 0 0 0.5em;
}

.vat-row .cart-vat-text {
    text-align: right;
    padding-right: 0;
}

#cart-page .cart-extras .checkbox.mva.price-vat-chk {
    min-width: 200px;
}

#cart-page .cart-extras .col-xs-4.price-vat-chk {
    width: 100%;
}

#cart-page .cart-extras .col-xs-4.price-vat-chk label {
    float: none !important;
    padding: 1em 0;
}



    .center-placeholder,
    #cart .panel-group,
    #cart .cart-extras,
    .checkout #carrier-selected .panel-body,
    .checkout #carrier-selected .panel-footer,
    .checkout .payment-content #payments-tab-nav li,
    .checkout .payment-content .tab-content .tab-pane {
        -webkit-transition: opacity 0.4s ease-in-out;
        -moz-transition: opacity 0.4s ease-in-out;
        -ms-transition: opacity 0.4s ease-in-out;
        -o-transition: opacity 0.4s ease-in-out;
        transition: opacity 0.4s ease-in-out;
    }


        .center-placeholder.refresh-data,
        .refresh-data .panel-group,
        .refresh-data .cart-extras,
        .checkout #carrier-selected.refresh-data .panel-body,
        .checkout #carrier-selected.refresh-data .panel-footer,
        .checkout .payment-content.refresh-data #payments-tab-nav li,
        .checkout .payment-content.refresh-data .tab-content .tab-pane {
            filter: alpha(opacity=20);
            opacity: 0.2;
        }



    .refresh-data:before {
        content: '\A';
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 999;
        top: 0;
        left: 0;
        font-size: 0;
    }

    .refresh-center {
        float: none;
        clear: both;
        text-align: center;
        z-index: 99999;
        font-size: 1em;
    }

    #checkout .refresh-center {
        position: relative;
    }

    .refresh-center-item {
        position: absolute;
        left: 20%;
        top: 40%;
        z-index: 9999;
        text-align: center;
    }

    #cart-page .refresh-center-item {
        top: 0;
    }

    #refresh-center .refresh-center-item {
        left: 47%;
        top: 40%;
        background: rgba(255, 255, 255, .7);
        border-radius: 20px;
    }

    .refresh-center-ico {
        text-align: center;
    }

    .refresh-center-item .icon-spinner,
    .refresh-center-item .icon-arrows-cw {
        font-size: 2em;
    }

    .refresh-txt {
        display: block;
        padding-top: 1em;
        font-size: 1em;
    }

    #cart .refresh-txt {
        background-color: #fff;
        padding: 0.5em 1em;
        border-radius: 4px;
    }

    .cart-config-show,
    .cart-config-hide {
        color: #0080c9;
        text-decoration: underline;
        cursor: pointer;
        padding: 10px 0 20px 0;
        font-weight: bold;
        font-size: 0.8em;
    }

    .ski-assembly-text {
        color: #0080c9;
        text-decoration: underline;
        cursor: pointer;
        padding: 0 0 20px 0;
        font-weight: bold;
        font-size: 0.8em;
        margin: 0;
    }

    .cart-config-ul {
        padding: 5px 0 5px 15px;
    }

    a.cart-config-child-info, a.cart-config-child-info:hover {
        text-decoration: none;
        font-size: 8pt;
        font-style: italic;
        color: initial;
        display: block;
    }

    /* OCI punchout - hide normal cart*/
    body.login-type-Oci a.cart-to-checkout {
        display: none !important;
    }

    body.login-type-Oci .smallcart-content a.cart-small-goto-checkout {
        display: none !important;
    }

    .cart-total-deductions .cart-items-total-deduction-txt, .cart-total-after-deductions .cart-items-total-after-deduction-txt {
        text-align: right;
    }

    .cart-total-deductions {
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        margin-top: 0.5em;
        margin-right: 0;
    }

    .cart-total-after-deductions {
        border-bottom: 4px double #ccc;
        margin-right: 0;
    }

    /*mport */
    .c-price-mport-reduction {
        width: 100%;
        display: table-row;
    }

    .c-price-mport-reduction-text, .c-price-mport-after-reduction-text {
        display: table-cell;
        width: auto;
    }

    .c-price-mport-reduction-value, .c-price-mport-after-reduction-value {
        text-align: right;
        width: 100%;
        display: table-cell;
    }
    /*end mport*/

    /* ------------------------------
	// END - Handlevogn
	------------------------------ */





    /*==================================================
=            Bootstrap 3 Media Queries             =
==================================================*/

    /*==========  Mobile First Method  ==========*/

    /* Custom, iPhone Retina */
    @media only screen and (min-width : 320px) {
    }

    /* Extra Small Devices, Phones */
    @media only screen and (min-width : 480px) {
        .cart-items .cart-img {
            margin-bottom: 0;
        }
    }

    /* Small Devices, Tablets */
    @media only screen and (min-width : 768px) {

        .cart-content .panel-group .panel.cart-panel-hide .panel-heading + .panel-collapse > .panel-body {
            padding: 15px;
        }

        .col-amount.input-group-sm > .input-group-btn > .btn {
            padding: 0.4em 0.6em;
            max-height: 4em;
        }

        .col-amount-xs .input-group .form-control {
            max-height: 4em;
        }

        #cart-page .cart-items {
            padding-left: 12px;
        }

        .cart-items .cart-img {
            margin-bottom: 0;
        }

        .cart-items .col-delete {
            padding-top: 0;
            margin-top: 1em;
        }

        .cart-items .show-cart-fav .col-delete {
            margin-top: 0px;
        }

        .cart-items .price-container {
            float: right;
            margin-top: 0;
        }

        .cart-items .col-amount-xs {
            margin-top: 0;
            margin-bottom: 0;
        }

        .cart-items .col-config-amount {
            text-align: center;
        }

        .cart-items .static-quantity {
            margin-top: 15px;
            margin-left: 5px;
        }

        .cart-items .c-price-single {
            display: block;
        }

        .cart-items .c-price-rrp {
            display: block;
        }

        .cart-items .col-price {
            margin-bottom: 0;
            margin-top: 1em;
        }

        .cart-items .col-price-shipping {
            margin-top: 0.5em;
        }

        .checkout .cart-items .col-price-shipping {
            margin-top: 1.5em;
            margin-bottom: 0;
        }

        .cart-items .row-line-last .col-xs-12 {
            padding: 5px 15px;
        }

        .row-to-checkout {
            margin: 40px;
        }

        a.btn.btn-primary.btn-block {
            margin-top: 0;
        }

        .cart-top {
            background-image: none;
            margin: 24px 0 10px 0;
            padding-bottom: 5px;
            border-bottom: 1px solid #ddd;
        }

            .cart-top h1 {
                color: #333;
                font-size: 16px;
                padding: 0;
                margin: 0;
            }

        .cart-items .col-amount {
            margin-top: 0;
        }

        .ui-panel-wrapper #checkout {
            background-color: #fff;
        }

        .cart-items-count-help {
            font-size: 0.8em;
            text-decoration: underline;
        }

        .cart-item-header2 {
            font-weight: normal;
        }

        .cart-extras .checkbox.mva {
            display: none;
        }

        .checkout .cart-extras .checkbox.mva {
            display: inline-block;
        }

        .cart-items .input-group.shipment-zipcode {
            padding: 0.5em 0 1.5em 0;
            margin: 0;
        }

        a.cart-carrier-show {
            margin-top: 0.5em;
            position: absolute;
        }

        .cart-config-show,
        .cart-config-hide,
        .ski-assembly-text {
            padding: 10px 0;
            font-weight: normal;
            font-size: 1em;
        }

        .cart-items .cart-vat-text,
        .cart-items .cart-vat-price {
            font-size: 10pt;
        }

        .refresh-center-item {
            left: 40%;
        }

        .cart-add-fav .btn.btn-primary.favorite-list-btn {
            display: block;
        }

        .cart-add-fav {
            margin-right: 0;
            margin-left: 5px;
        }
    }
    /* END - Small Devices, Tablets (min-width : 768px) */

    /* Medium Devices, Desktops */
    @media only screen and (max-width : 992px) {
        .cart-item .col-amount-xs {
            margin-bottom: 10px;
        }
    }



    /* Medium Devices, Desktops */
    @media only screen and (min-width : 992px) {

        .cart-add-fav {
            margin-left: 9px;
        }
    }

    /* Large Devices, Wide Screens */
    @media only screen and (min-width : 1200px) {
        .cart-add-fav {
            /*margin-left: 22px;*/
        }
    }

    /*==================================================
=  END       Bootstrap 3 Media Queries             =
==================================================*/

    /* Preorder cart-stuff */
    .preorder-controls {
        margin: 10px 15px;
    }

        .preorder-controls .preorder-dates,
        .preorder-controls > .btn {
            float: left;
        }

        .preorder-controls .btn.delete-orderbook {
            margin-right: 10px;
        }

        .preorder-controls .btn .glyphicon {
            margin-right: 0.9em;
        }
    /* End preorder cart-stuff */




















.cart-alteration-edit {
    position: absolute;
    margin-top: -25px;
}

.pagetype-cart-alteration .login-container,
.pagetype-cart-alteration .top-menu,
.pagetype-cart-alteration .HeaderSearch,
.pagetype-cart-alteration .top-menu,
.pagetype-cart-alteration #content-special-offers,
.pagetype-cart-alteration .smallcart-content,
.pagetype-cart-alteration .SubMenuArea,
.pagetype-cart-alteration #HeadTop,
.pagetype-cart-alteration #PageFooter,
.pagetype-cart-alteration .LanguageSelection,
.pagetype-cart-alteration .SlideshowContainer {
    display: none;
}

#cart-alteration .form-control {
    border-radius: 4px;
/*    box-shadow: none;*/
}
    #cart-alteration .form-control.input-quantity {
        border-radius: 0;
    }


.cart-alteration-summary .form-control {
    border: none;
    box-shadow: none;
}

    .cart-alteration-summary .form-control[disabled] {
        background-color: #fff;
    }

.pagetype-cart-alteration a.header-logo {
    top: 10px;
}

.pagetype-cart-alteration .container-top-menu {
    display: none !important;
}

.pagetype-cart-alteration .home {
    display: block;
    font-size: 40px;
    padding-top: 12px;
}

#cart-alteration .table-bordered > tbody > tr > td {
    border: none;
}

@media (max-width: 767px) {
    .search-expanded.pagetype-cart-alteration .HeaderSearch {
        display: none;
    }

    .pagetype-cart-alteration .panel-body {
        padding: 0;
    }

    .pagetype-cart-alteration .menu-container {
        display: none;
    }

    

    #cart-alteration td.prod-image {
        position: absolute;
    }

    #cart-alteration .col-amount {
        margin-top: 20px;
        max-width: none;
    }

    #cart-alteration .header-xs {
        float: left;
    }

    #cart-alteration .col-amount.input-group-sm > .input-group-btn > .btn {
        padding-left: 3em;
        padding-right: 3em;
    }

    #cart-alteration .table-bordered,
    #cart-alteration .table-striped > tbody > tr {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom-style: dashed;
    }

    .cart-alteration-confirm {
        padding-right: 5px;
    }

    

    .pagetype-cart-alteration.search-expanded .header-main {
        height: 48px;
    }

    .pagetype-cart-alteration .home {
        padding-top: 0;
    }
}

#cart-alteration .panel-heading {
    border-bottom: none;
}
#cart-alteration th {
    background-color: #103c66;
    color: #ffffff;
}

#cart-alteration .table-bordered .table-bordered > thead > tr > th {
    border: 1px solid #333;
    border-radius: 5px;
}


@media (min-width : 992px) {
    .pagetype-cart-alteration .home {
        margin-right: 100px;
    }
}

.cart-giftcard-items .row {
    margin-bottom: 5px;
}

.remove-giftcard {
    text-align: right;
    line-height: 1;
}

.remove-giftcard a {
    display: inline-block;
    line-height: 1;
}

.remove-giftcard a i {
    line-height: 1;
    display: inline-block;
}

.cart-voucher-val {
    text-align: right;
}

#cartpanel .campaign-info{
    font-size:smaller;
    font-style:italic;
}

.cart-item-marked::after {
    content:'*';
    color:red;
    position:absolute;
    right:0px;
}

.cart-item-marked-info::after {
    content: '*';
    color: red;
    position: absolute;
    left: 0px;
}

@media (min-width : 768px) {
    .col-sm-0 {
        display: none;
    }
}

@media (max-width : 767px) {
    .col-xs-0.col-sm-1 {
        display: none;
    }
}